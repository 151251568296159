import { useEffect, useState } from 'react'
import { GetAlertsParams } from '../../functions/src/types'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { app } from '../firebase'
import { DEFAULT_REGION } from 'shared/firebase/region'
import { Serial } from 'shared/types/utils'
import { dateTimeFromISO } from 'shared/utils/time'
import { Alerts, AlertType } from 'shared/types/alert'
import { FirebaseKey } from 'shared/types/utils'
import { Duration } from 'luxon'

type FormattedAlert = {
  validatedTypes: AlertType[]
  recordedTypes: AlertType[]
  comments: string[]
  delays: string[]
}

export type FormattedAlerts = Record<FirebaseKey, FormattedAlert>

const functions = getFunctions(app, DEFAULT_REGION)
const getAlerts = httpsCallable<GetAlertsParams, Alerts>(functions, 'getAlerts')

export const useAlerts = (serial: Serial, date: string | undefined) => {
  const [alerts, setAlerts] = useState<FormattedAlerts>({})

  useEffect(() => {
    async function reloadPredictions() {
      if (date) {
        const result = await getAlerts({
          serial,
          date,
        })
        const data = transposeResultsAlerts(result.data)
        setAlerts(data)
      }
    }

    reloadPredictions()
  }, [serial, date])

  return alerts
}

function timeDifference(timestamp1: number, timestamp2: number): string {
  const differenceInMillis = timestamp2 - timestamp1
  const duration = Duration.fromMillis(differenceInMillis).shiftTo(
    'hours',
    'minutes',
    'seconds',
  )

  const formattedDuration = duration.toFormat('+hh:mm:ss')

  return formattedDuration
}

function transposeResultsAlerts(alerts: Alerts): FormattedAlerts {
  if (!alerts) {
    return {}
  }
  const formatedAlerts: FormattedAlerts = {}

  Object.entries(alerts).forEach(([_, alert]) => {
    if (!alert) return
    const ts = dateTimeFromISO(alert.date).toMillis().toString()

    const existingAlert = formatedAlerts[ts] ?? {
      validatedTypes: [],
      recordedTypes: [],
      comments: [],
      delays: [],
    }

    if (alert.validation) {
      existingAlert.validatedTypes.push(alert.type)

      const delay = timeDifference(
        dateTimeFromISO(alert.date).toMillis(),
        alert.validation.timeStamp,
      )

      existingAlert.delays.push(delay)
    } else {
      existingAlert.recordedTypes.push(alert.type)
      existingAlert.comments.push(alert.comment ?? 'No information')
    }

    formatedAlerts[ts] = existingAlert
  })

  return formatedAlerts
}
