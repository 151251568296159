import { LanguageOption } from './types'

export const AWS_S3_REGION = 'eu-west-3'
export const AZURE_SPEECH_TRANSLATION_TARGET_LANGUAGE = 'fr'
export const DEFAULT_RECOGNITION_LANGUAGE = 'en-US'

export const AZURE_LANGUAGE_MAP: Record<string, string> = {
  // Most common languages
  'da-DK': 'Danois',
  'it-IT': 'Italien',
  'es-ES': 'Espagnol',
  'en-GB': 'Anglais (Royaume-Uni)',
  'ja-JP': 'Japonais',
  'fr-FR': 'Français',

  // European languages
  'de-DE': 'Allemand',
  'pt-PT': 'Portugais',
  'nl-NL': 'Néerlandais',
  'en-US': 'Anglais (États-Unis)',
  'sv-SE': 'Suédois',
  'fi-FI': 'Finnois',
  'no-NO': 'Norvégien',

  // Other world languages
  'zh-CN': 'Chinois (Mandarin)',
  'ru-RU': 'Russe',
  'ar-AE': 'Arabe (EAU)',
  'tr-TR': 'Turc',
  'he-IL': 'Hébreu',
  'ko-KR': 'Coréen',
  'hi-IN': 'Hindi',
  'pt-BR': 'Portugais (Brésil)',

  // Regional variants and less common languages
  'af-ZA': 'Afrikaans',
  'ar-DZ': 'Arabe (Algérie)',
  'eu-ES': 'Basque',
  'hr-HR': 'Croate',
  'is-IS': 'Islandais',
  'ga-IE': 'Irlandais',
  'it-CH': 'Italien (Suisse)',
  'fr-BE': 'Français (Belgique)',
  'fr-CA': 'Français (Canada)',
  'fr-CH': 'Français (Suisse)',
  'nl-BE': 'Néerlandais (Belgique)',
}

export const LANGUAGE_OPTIONS: LanguageOption[] = Object.entries(
  AZURE_LANGUAGE_MAP,
)
  .map(([code, name]) => ({ code, name }))
  .sort((a, b) => a.name.localeCompare(b.name))

export const AZURE_CANDIDATE_LANGUAGE_CODES = Object.keys(AZURE_LANGUAGE_MAP)
