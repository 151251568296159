import React from 'react'
import { MetricsConfig } from 'shared/utils/web/metrics'
import { Predictions } from './hooks/usePredictions'

function maxPerClass(predictions: Predictions) {
  const maxValues: number[] = []
  Object.values(predictions).map((values) => {
    for (let index = 0; index < values.length; index++) {
      const value = values[index] ?? 0
      if (!maxValues[index] || value > maxValues[index]) {
        maxValues[index] = value
      }
    }
  })
  return maxValues
}

type Props = {
  predictions: Predictions
  metricsConfig: MetricsConfig
}

const PredictionsPreview_: React.FC<Props> = ({
  predictions,
  metricsConfig,
}) => {
  const aggregatedPredictions = maxPerClass(predictions)
  return (
    <div className={`flex flex-1 flex-col`}>
      {Object.entries(metricsConfig).map(([metricKey, metricConfig], index) => {
        const opacity = aggregatedPredictions[index]
        return (
          <div
            key={metricKey}
            className="flex-1"
            style={{
              backgroundColor: metricConfig.color,
              opacity,
            }}
          />
        )
      })}
    </div>
  )
}

export const PredictionsPreview = React.memo(PredictionsPreview_)
