import { getFunctions, httpsCallable } from 'firebase/functions'
import { useCallback, useState } from 'react'
import { DEFAULT_REGION } from 'shared/firebase/region'
import { onError } from 'shared/utils/web/error'
import { TranslateSpeechResponseData } from '../common/types'
import { app } from '../firebase'

const functions = getFunctions(app, DEFAULT_REGION)
const transcribeAndTranslateParticle = httpsCallable(
  functions,
  'transcribeAndTranslateParticle',
)

export const useTranslateParticle = () => {
  const [speechResult, setSpeechResult] =
    useState<TranslateSpeechResponseData | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const translateParticle = useCallback(
    async (
      particleRef: string,
      bucket: string,
      candidateLanguages: string[],
    ) => {
      setLoading(true)
      setSpeechResult(null)

      try {
        const result = await transcribeAndTranslateParticle({
          particleRef,
          bucket,
          candidateLanguages, // Optional, defaults to 'fr-FR' on the server
        })
        setSpeechResult(result.data as TranslateSpeechResponseData)
      } catch (error) {
        onError(error)
      } finally {
        setLoading(false)
      }
    },
    [],
  )

  return {
    speechResult,
    translateParticle,
    loading,
  }
}
