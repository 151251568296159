import { useState } from 'react'
import {
  LabelingDialog,
  buildUrl,
  AddToLabelingQueueParams,
} from './components/LabelingDialog'
import { Button } from './components/Button'
import { fetch_ } from 'shared/utils/fetch'

type Props = {
  soundKey: string | undefined
}

export function LabelsDialog({ soundKey }: Props) {
  const [showLabelsDialog, setshowLabelsDialog] = useState(false)

  async function handleAddToLabelingQueue() {
    const payload = [
      {
        particles: [`${soundKey}`],
        source: AddToLabelingQueueParams.source,
        why: AddToLabelingQueueParams.why,
        priority: AddToLabelingQueueParams.priority,
        labelersTarget: AddToLabelingQueueParams.labelersTarget,
      },
    ]

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }

    const url = buildUrl('labels', 'addToLabelingQueue')
    await fetch_(url, options)
  }

  function handleLabeling() {
    const url = `${buildUrl('labels', 'openInApp')}/${soundKey}`
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <>
      <div onClick={() => setshowLabelsDialog(true)}>
        <Button>Labels</Button>
      </div>
      <LabelingDialog
        open={showLabelsDialog}
        onClose={() => setshowLabelsDialog(false)}
        onLabel={() => handleLabeling()}
        onAddToQueue={() => handleAddToLabelingQueue()}
      >
        <div className="mt-5 flex items-center justify-center text-lg">
          Choisissez une action
        </div>
      </LabelingDialog>
    </>
  )
}
