import { useState } from 'react'
import {
  LabelingDialog,
  buildUrl,
  AddToLabelingQueueParams,
} from './components/LabelingDialog'
import { Button } from './components/Button'
import { Input } from './components/Input'
import { fetch_ } from 'shared/utils/fetch'

type Props = {
  serial: string
  currentTime: string
}
const optionsScope = [
  { label: 'Court', value: 'short' },
  { label: 'Moyen', value: 'mid' },
  { label: 'Long', value: 'long' },
]

export function SequenceDialog({ serial, currentTime }: Props) {
  const [scope, setScope] = useState<string>('mid')
  const [showSequenceDialog, setshowSequenceDialog] = useState(false)
  const [hint, setHint] = useState<string>('')
  const [isRandomOffset, setIsRandomOffset] = useState<boolean>(true)

  async function handleAddToLabelingQueue(
    hint: string,
    scope: string,
    isRandomOffset: boolean,
  ) {
    const payload = [
      {
        instants: [`${serial}/${currentTime}`],
        source: AddToLabelingQueueParams.source,
        why: AddToLabelingQueueParams.why,
        priority: AddToLabelingQueueParams.priority,
        labelersTarget: AddToLabelingQueueParams.labelersTarget,
        avgStep: scope,
        addRandomOffsetToRef: isRandomOffset,
        hint: hint,
      },
    ]

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }

    const url = buildUrl('sequence', 'addToLabelingQueue')
    await fetch_(url, options)
  }

  function handleLabeling(
    scope: string,
    hint: string,
    addRandomOffsetToRef: boolean,
  ) {
    const url = `${buildUrl('sequence', 'openInApp')}/${serial}/${currentTime}?scope=${scope}&hint=${hint}&addRandomOffsetToRef=${addRandomOffsetToRef}`
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <>
      <Button onClick={() => setshowSequenceDialog(true)}>Sequence</Button>
      <LabelingDialog
        open={showSequenceDialog}
        onClose={() => setshowSequenceDialog(false)}
        onLabel={() => handleLabeling(scope, hint, isRandomOffset)}
        onAddToQueue={() =>
          handleAddToLabelingQueue(hint, scope, isRandomOffset)
        }
      >
        <div className="grid grid-cols-2">
          <div className="flex flex-col gap-2">
            {optionsScope.map(({ label, value }) => (
              <div key={value}>
                <input
                  type="radio"
                  id={value}
                  value={value}
                  checked={scope === value}
                  onChange={() => setScope(value)}
                />
                <label htmlFor={value} className="ml-2">
                  {label}
                </label>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-2">
              <label htmlFor="indiceInput" className="mr-2">
                Indice :
              </label>
              <Input
                id="indiceInput"
                type="text"
                placeholder="Entrez un indice"
                value={hint}
                onChange={(e) => setHint(e.target.value)}
              />
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="randomOffset"
                checked={isRandomOffset}
                onChange={(e) => setIsRandomOffset(e.target.checked)}
              />
              <label htmlFor="randomOffset" className="ml-2">
                Décalage aléatoire
              </label>
            </div>
          </div>
        </div>
      </LabelingDialog>
    </>
  )
}
