import { AZURE_LANGUAGE_MAP, LANGUAGE_OPTIONS } from 'common/speechConstants'
import { BucketName, MilvusMode } from 'common/types'
import { DateTime } from 'luxon'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Predictions as PredictionsComponent,
  verticalBlockStyle,
} from 'shared/components/Predictions'
import { TimeMarks } from 'shared/components/TimeMarks'
import { useInterval } from 'shared/hooks/useInterval'
import { Serial } from 'shared/types/utils'
import { fetch_ } from 'shared/utils/fetch'
import { dateTimeFromISO, ms2sec, sec2ms } from 'shared/utils/time'
import {
  MAX_VOL,
  MIN_VOL,
  computeAdmissibleVolume,
} from 'shared/utils/web/audio'
import { onError } from 'shared/utils/web/error'
import { S3Sound } from 'shared/utils/web/fetchData'
import { MetricsConfig } from 'shared/utils/web/metrics'
import { aggregateSoundsData } from './aggregateSoundsData'
import { AlertsVisualizer } from './components/AlertsVisualizer'
import { Button } from './components/Button'
import { DropdownCheckboxes } from './components/DropdownCheckboxes'
import { Loader } from './components/Loader'
import { Select } from './components/Select'
import { drawSoundsData } from './drawSoundsData'
import { EventDialog } from './EventDialog'
import { GraphCache, SOUND_DURATION, getDateTimeFromURL } from './Explorer'
import { useAlerts } from './hooks/useAlerts'
import { useMilvus } from './hooks/useMilvus'
import { Predictions } from './hooks/usePredictions'
import { useTranslateParticle } from './hooks/useTranslateParticle'
import { LabelsDialog } from './LabelsDialog'
import { useS3Context } from './S3Provider'
import { SequenceDialog } from './SequenceDialog'
import { TimeWindowDialog } from './TimeWindowDialog'

type Props = {
  getS3Url: (key: string) => Promise<string>
  serial: Serial
  prefix: string
  data: S3Sound[]
  startDate: DateTime
  endDate: DateTime
  contrast: number
  graphCache: GraphCache
  predictions: Predictions
  metricsConfig: MetricsConfig
}

export function Player({
  getS3Url,
  serial,
  prefix,
  data,
  startDate,
  endDate,
  contrast,
  graphCache,
  predictions,
  metricsConfig,
}: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const trackRef = useRef<HTMLDivElement>(null)

  const [start, end] = [startDate.valueOf(), endDate.valueOf()]

  const [soundTexts, setSoundTexts] = useState<
    Record<string, string | undefined | null>
  >({})
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [percent, setPercent] = useState<number>(0)
  const [playbackRate, setPlaybackRate] = useState<number>(1)
  const [currentSoundKey, setCurrentSoundKey] = useState<string>()
  const [processedSoundKeys, setProcessedSoundKeys] = useState<string[]>([])
  const [computationInProgress, setComputationInProgress] =
    useState<boolean>(false)
  const [initializedFromURL, setInitializedFromURL] = useState<boolean>(false)

  const [fetchingSoundUrls, setFetchingSoundUrls] = useState<boolean>(false)
  const soundURLs = useRef<Record<string, string>>({})
  const audioRefs = useRef<Record<string, React.RefObject<HTMLAudioElement>>>(
    {},
  )
  const [selectedMilvusMode, setSelectedMilvusMode] =
    useState<MilvusMode>('all')

  const { bucketName } = useS3Context()

  const currentDate = useMemo(() => {
    if (!currentSoundKey) return undefined
    return currentSoundKey.replace('.ogg', '').split('/')[1]
  }, [currentSoundKey])

  const { similarParticles, searchSimilarParticles, milvusLoading } = useMilvus(
    selectedMilvusMode as MilvusMode,
    bucketName as BucketName,
  )

  const {
    speechResult,
    translateParticle,
    loading: speechProcessingLoading,
  } = useTranslateParticle()

  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([
    'fr-FR',
  ])

  const getLanguageNamesFromCodes = (codes: string[]): string[] => {
    return codes.map((code) => AZURE_LANGUAGE_MAP[code] || code)
  }

  // Helper to get all language codes from their names
  const getLanguageCodesFromNames = (names: string[]): string[] => {
    return names.map((name) => {
      const option = LANGUAGE_OPTIONS.find((opt) => opt.name === name)
      return option ? option.code : name
    })
  }

  const handleLanguageChange = (selectedLanguageNames: string[]) => {
    const languageCodes = getLanguageCodesFromNames(selectedLanguageNames)
    setSelectedLanguages(languageCodes)
  }

  const handleSpeechProcessing = useCallback(() => {
    if (currentSoundKey) {
      const particleRef = currentSoundKey.replace('.ogg', '')
      translateParticle(particleRef, bucketName, selectedLanguages)
    }
  }, [currentSoundKey, bucketName, selectedLanguages, translateParticle])

  const alerts = useAlerts(serial, currentDate)

  // Disables similarity search if currentSoundKey is older than the time Milvus stores data for
  const MILVUS_TIME_LIMIT_IN_DAYS = 4

  const isSoundKeyOlderThan = (
    soundKey: string,
    maxAgeInDays: number,
  ): boolean => {
    const particleRef = soundKey.replace('.ogg', '')
    const dateStr = particleRef.split('/')[2]
    const dateToCheck = dateTimeFromISO(dateStr)
    const currentDate = DateTime.now()
    const diffInDays = currentDate.diff(dateToCheck, 'days').days
    return diffInDays > maxAgeInDays
  }

  const [isSimilaritySearchEnabled, setIsSimilaritySearchEnabled] =
    useState(false)

  useEffect(() => {
    if (bucketName === 'oso-resp-sounds') {
      setIsSimilaritySearchEnabled(true)
    } else if (bucketName === 'oso-sounds' && currentSoundKey) {
      setIsSimilaritySearchEnabled(
        !isSoundKeyOlderThan(currentSoundKey, MILVUS_TIME_LIMIT_IN_DAYS),
      )
    }
  }, [currentSoundKey, bucketName])

  const [admissibleVolume, setAdmissibleVolume] = useState(MAX_VOL)
  const [volume, setVolume] = useState(MAX_VOL)
  const [autoVolumeAdjust, setAutoVolumeAdjust] = useState(true)

  // Retrieve sounds timestamps from both data and predictions
  const soundsTimestamps = useMemo(
    () => [
      ...new Set(
        data
          .map(({ endTimestamp }) => endTimestamp)
          .concat(Object.keys(predictions).map((ts) => parseInt(ts))),
      ),
    ],
    [data, predictions],
  )

  // Compute or update soundURLs
  useEffect(() => {
    setFetchingSoundUrls(true)
    Promise.all(
      data.map(async ({ soundKey }) => {
        if (soundURLs.current[soundKey] === undefined) {
          soundURLs.current[soundKey] = await getS3Url(soundKey)
        }
      }),
    ).then(() => setFetchingSoundUrls(false))
  }, [data, getS3Url])

  // Compute or update audio refs
  useEffect(() => {
    data.forEach(({ soundKey }) => {
      if (audioRefs.current[soundKey] === undefined)
        audioRefs.current[soundKey] = React.createRef<HTMLAudioElement>()
    })
  }, [data])

  const currentAudioElement =
    currentSoundKey && audioRefs.current
      ? (audioRefs.current[currentSoundKey]?.current ?? null)
      : null

  // Set currentAudioElement volume when volume state is updated
  useEffect(() => {
    if (currentAudioElement === null) return
    currentAudioElement.volume = volume
  }, [volume, currentAudioElement])

  const setTimeStamp = useCallback(
    (timestamp: number) => {
      let index
      let localTimestamp = 0
      for (index = 0; index < data.length; index++) {
        const { endTimestamp } = data[index]
        const startTimestamp = endTimestamp - SOUND_DURATION
        if (timestamp <= endTimestamp && timestamp >= startTimestamp) {
          localTimestamp = ms2sec(timestamp - startTimestamp)
          break
        }
        if (startTimestamp > timestamp) {
          break
        }
      }

      if (index < data.length) {
        const newSoundKey = data[index].soundKey
        if (newSoundKey !== currentSoundKey) {
          currentAudioElement?.pause()
          setCurrentSoundKey(newSoundKey)
        }

        const newAudioElement = audioRefs.current[newSoundKey].current
        if (newAudioElement === null) return

        newAudioElement.currentTime = localTimestamp
      }
    },
    [data, currentSoundKey, currentAudioElement, audioRefs],
  )

  // Load sound texts
  useEffect(() => {
    for (const { soundKey, textKey } of data) {
      if (textKey !== undefined && soundTexts[soundKey] === undefined) {
        setSoundTexts((soundTexts) => ({ ...soundTexts, [soundKey]: null }))
        const fetchText = async () => {
          const textUrl = await getS3Url(textKey)
          const response = await fetch_(textUrl)
          const text = await response.text()
          setSoundTexts((soundTexts) => ({ ...soundTexts, [soundKey]: text }))
        }
        fetchText()
      }
    }
  }, [data, getS3Url, soundTexts])

  // Compute graph data
  useLayoutEffect(() => {
    if (computationInProgress) return
    if (fetchingSoundUrls) return
    setComputationInProgress(true)

    const canvas = canvasRef.current
    if (canvas === null) {
      console.error('Canvas not found')
      return
    }
    const audioContext = new AudioContext()
    const canvasWidth = Math.floor(canvas.offsetWidth)

    let counts: number[]
    let sums: number[]

    if (graphCache && graphCache.has(prefix)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const cache = graphCache.get(prefix)!
      counts = cache.counts
      sums = cache.sums
    } else {
      counts = new Array(canvasWidth).fill(0)
      sums = new Array(canvasWidth).fill(0)
    }

    const dataToProcess = data.filter(
      ({ soundKey }) =>
        !processedSoundKeys.includes(soundKey) &&
        soundURLs.current[soundKey] !== undefined,
    )

    if (dataToProcess.length > 0) {
      Promise.all(
        dataToProcess.map(async ({ endTimestamp, soundKey }) => {
          try {
            const soundUrl = soundURLs.current[soundKey]
            const response = await fetch_(soundUrl)
            const arrayBuffer = await response.arrayBuffer()
            const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)
            aggregateSoundsData(
              counts,
              sums,
              start,
              end,
              endTimestamp,
              audioBuffer,
              canvasWidth,
            )
            drawSoundsData(canvas, counts, sums)
          } catch (error) {
            onError(error)
          }
        }),
      ).then(() => {
        setProcessedSoundKeys([
          ...processedSoundKeys,
          ...dataToProcess.map(({ soundKey }) => soundKey),
        ])
        graphCache.set(prefix, { counts, sums })
        setComputationInProgress(false)
      })
    } else {
      drawSoundsData(canvas, counts, sums)
      setComputationInProgress(false)
    }
  }, [
    data,
    prefix,
    soundURLs,
    graphCache,
    processedSoundKeys,
    computationInProgress,
    start,
    end,
    serial,
    fetchingSoundUrls,
  ])

  // Helper function for prev/next
  const startPlayAtIndex = useCallback(
    (index: number) => {
      currentAudioElement?.pause()
      const soundKey = data[index].soundKey
      setCurrentSoundKey(soundKey)
      const audioElement = audioRefs.current[soundKey].current
      if (audioElement === null) return
      audioElement.currentTime = 0
      if (isPlaying) audioElement.play()
    },
    [currentAudioElement, data, isPlaying],
  )

  const getCurrentSoundIndex = useCallback(
    () => data.findIndex((soundData) => soundData.soundKey === currentSoundKey),
    [data, currentSoundKey],
  )

  const previousSound = useCallback(() => {
    if (currentAudioElement === null) return
    const currentSoundIndex = getCurrentSoundIndex()

    if (currentAudioElement.currentTime * 1000 < 0.1 * SOUND_DURATION) {
      if (currentSoundIndex > 0) {
        startPlayAtIndex(currentSoundIndex - 1)
      }
    } else {
      currentAudioElement.currentTime = 0
    }
  }, [getCurrentSoundIndex, startPlayAtIndex, currentAudioElement])

  const nextSound = useCallback(() => {
    const currentSoundIndex = getCurrentSoundIndex()

    if (currentSoundIndex < data.length - 1) {
      startPlayAtIndex(currentSoundIndex + 1)
    }
  }, [data, getCurrentSoundIndex, startPlayAtIndex])

  // Set sound index to first sound by default, would be overwritten if from URL
  useEffect(() => {
    if (
      currentSoundKey === undefined ||
      !data.map(({ soundKey }) => soundKey).includes(currentSoundKey)
    )
      if (data.length !== 0) startPlayAtIndex(0)
      else {
        currentAudioElement?.pause()
        setCurrentSoundKey(undefined)
      }
  }, [currentAudioElement, currentSoundKey, data, startPlayAtIndex])

  // Cue time from URL on start
  useEffect(() => {
    const dateTime = getDateTimeFromURL()
    if (dateTime && !initializedFromURL) {
      const soundIndex = data.findIndex(
        ({ endTimestamp }) => dateTime.valueOf() === endTimestamp,
      )
      // startPlayAtIndex only if sound exists
      if (soundIndex >= 0) startPlayAtIndex(soundIndex)
      setInitializedFromURL(true)
    }
  }, [setTimeStamp, initializedFromURL, data, startPlayAtIndex])

  // change URL on sound change
  useEffect(() => {
    if (currentSoundKey !== undefined)
      window.history.replaceState(
        null,
        '',
        `${window.location.origin}/${currentSoundKey.replace('.ogg', '')}`,
      )
  }, [currentSoundKey, currentAudioElement])

  // Handle sound player events, play next at end
  useEffect(() => {
    if (currentAudioElement === null) return

    const handleCanPlay = () => {
      if (currentSoundKey === undefined) return

      const AudioContext =
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        window.AudioContext || (window as any).webkitAudioContext

      const audioContext = new AudioContext()

      fetch_(soundURLs.current[currentSoundKey])
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
        .then((audioBuffer) => {
          if (audioBuffer === undefined)
            throw Error('Unable to decode audio data')
          const newAdmissibleVolume = computeAdmissibleVolume(audioBuffer)
          setAdmissibleVolume(newAdmissibleVolume)
          if (autoVolumeAdjust) setVolume(newAdmissibleVolume)
        })
        .catch(onError)

      currentAudioElement.play()
    }
    const handlePlay = () => setIsPlaying(true)
    const handlePause = () => setIsPlaying(false)
    const handleError = (error: ErrorEvent): void => {
      console.error('Audio error', error)
    }

    currentAudioElement.addEventListener('canplay', handleCanPlay)
    currentAudioElement.addEventListener('play', handlePlay)
    currentAudioElement.addEventListener('pause', handlePause)
    currentAudioElement.addEventListener('ended', nextSound)
    currentAudioElement.addEventListener('error', handleError)

    return () => {
      currentAudioElement.removeEventListener('canplay', handleCanPlay)
      currentAudioElement.removeEventListener('play', handlePlay)
      currentAudioElement.removeEventListener('pause', handlePause)
      currentAudioElement.removeEventListener('ended', nextSound)
      currentAudioElement.removeEventListener('error', handleError)
    }
  }, [
    data,
    currentAudioElement,
    audioRefs,
    nextSound,
    currentSoundKey,
    autoVolumeAdjust,
  ])

  // Playback rate
  useEffect(() => {
    if (currentAudioElement === null) return
    currentAudioElement.playbackRate = playbackRate
  }, [playbackRate, currentAudioElement])

  // Update percent while playing
  function updatePercent() {
    if (data.length === 0) return
    if (!currentAudioElement) return

    const currentSoundIndex = getCurrentSoundIndex()

    const soundStartTimeStamp =
      data[currentSoundIndex].endTimestamp - SOUND_DURATION
    const localCurrentTimestamp = sec2ms(currentAudioElement.currentTime)
    const globalCurrentTimestamp = soundStartTimeStamp + localCurrentTimestamp

    setPercent((globalCurrentTimestamp - start) / (end - start))
  }

  useInterval(updatePercent, 250)

  const togglePlayPause = useCallback(() => {
    if (currentAudioElement === null) return

    if (currentAudioElement.paused) {
      // When coming from URL, volume may not adjust automatically for unknown reason, so default volume must be used
      currentAudioElement.volume = volume
      currentAudioElement.play()
    } else currentAudioElement.pause()
  }, [currentAudioElement, volume])

  const handleTrackClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (data.length === 0) return
      if (trackRef.current === null) return
      const trackRect = trackRef.current.getBoundingClientRect()
      const percent = (event.clientX - trackRect.left) / trackRect.width
      setTimeStamp(start + (end - start) * percent)
    },
    [data, setTimeStamp, start, end],
  )

  const handlePlaybackRateClick = useCallback(() => {
    const playbackRates = [1, 1.25, 1.5, 2, 3]
    setPlaybackRate((prevPlaybackRate) => {
      return playbackRates[
        (playbackRates.indexOf(prevPlaybackRate) + 1) % playbackRates.length
      ]
    })
  }, [])

  // Keyboard handlers
  useEffect(() => {
    if (currentAudioElement === null) return

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === ' ') {
        e.preventDefault()
        togglePlayPause()
      } else if (e.key === 'ArrowLeft') {
        previousSound()
      } else if (e.key === 'ArrowRight') nextSound()
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [currentAudioElement, togglePlayPause, previousSound, nextSound])

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === '+') {
        setVolume((volume) => Math.min(1, Math.max(0, volume + 0.05)))
      } else if (e.key === '-') {
        setVolume((volume) => Math.min(1, Math.max(0, volume - 0.05)))
      }
    }

    window.addEventListener('keypress', handleKeyPress)
    return () => window.removeEventListener('keypress', handleKeyPress)
  })

  const currentTime = DateTime.fromSeconds(
    Math.round(ms2sec(start + (end - start) * percent)),
  ).toISO({ suppressMilliseconds: true })

  // Copy URL to clipboard
  const [isListCopied, setIsListCopied] = useState(false)
  const copyAllParticlesToClipboard = async () => {
    const allParticles = JSON.stringify(similarParticles)
    await navigator.clipboard.writeText(allParticles)
    setIsListCopied(true)
    setTimeout(() => setIsListCopied(false), 3000)
  }

  const copyTranslationToClipboard = async () => {
    if (speechResult) {
      const textToCopy = `Transcription : ${speechResult.originalText}${
        speechResult.translatedText
          ? `\nTraduction : ${speechResult.translatedText}`
          : ''
      }`
      await navigator.clipboard.writeText(textToCopy)
      setIsListCopied(true)
      setTimeout(() => setIsListCopied(false), 3000)
    }
  }

  return (
    <div>
      {soundURLs && (
        <div>
          {data.map((soundData) => (
            <audio
              crossOrigin="anonymous"
              key={soundData.soundKey}
              src={soundURLs.current[soundData.soundKey]}
              ref={audioRefs.current[soundData.soundKey]}
            />
          ))}
        </div>
      )}
      <div className="flex flex-row gap-8">
        <div className="flex flex-col gap-2">
          <Button
            className="flex !h-20 !w-20 flex-none items-center justify-center !rounded-full !text-2xl"
            onMouseUp={togglePlayPause}
          >
            {isPlaying ? '❚❚' : '▶'}
          </Button>
          <div className="tabular-nums">
            {startDate
              .plus({ milliseconds: (end - start) * percent })
              .toLocaleString(DateTime.TIME_24_WITH_SECONDS)}
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-4">
          <div className="relative" ref={trackRef} onClick={handleTrackClick}>
            <canvas className="h-24 w-full" ref={canvasRef}></canvas>
            {soundsTimestamps.map((ts) => {
              const isSoundAvailable = data
                .map((soundData) => soundData.endTimestamp)
                .includes(ts)
              const left = Math.max(ts - SOUND_DURATION, start)
              const right = Math.min(ts, end)
              if (right < left || isSoundAvailable) return null
              return (
                <div
                  className="absolute inset-y-0 z-10 bg-red-400 bg-opacity-30"
                  key={ts}
                  style={verticalBlockStyle(left, right, start, end - start)}
                ></div>
              )
            })}
            <div
              className="absolute inset-y-0 z-20 -ml-0.5 w-1 bg-white"
              style={{ left: `${percent * 100}%` }}
            />
          </div>
          <AlertsVisualizer alerts={alerts} start={start} end={end} />
          <div
            className="relative"
            style={{ height: `${7 * Object.keys(metricsConfig).length}px` }}
          >
            {soundsTimestamps.map((ts) => {
              const left = Math.max(ts - SOUND_DURATION, start)
              const right = Math.min(ts, end)
              if (right < left) return null
              return (
                <PredictionsComponent
                  key={ts}
                  left={left}
                  right={right}
                  start={start}
                  end={end}
                  contrast={contrast / 100}
                  text={soundTexts[ts]}
                  predictions={predictions[ts]}
                  metricsConfig={metricsConfig}
                />
              )
            })}
          </div>
          <TimeMarks start={startDate} end={endDate} count={10} />
          <div className="flex flex-row gap-2">
            <Button
              className={`flex gap-2 !rounded-full ${
                autoVolumeAdjust ? '' : '!bg-red-600'
              }`}
              onClick={() =>
                autoVolumeAdjust
                  ? setAutoVolumeAdjust(false)
                  : setAutoVolumeAdjust(true)
              }
            >
              <span className="text-2xl" role="img" aria-label="Volume">
                {volume > 0.66 ? '🔊' : volume > 0.33 ? '🔉' : '🔈'}
              </span>
              <span className="self-center">
                {autoVolumeAdjust ? 'Auto' : 'Manuel'}
              </span>
            </Button>

            <input
              className={`flex-1 ${
                volume - admissibleVolume > 0.2
                  ? 'accent-red-600'
                  : volume - admissibleVolume > 0
                    ? 'accent-yellow-300'
                    : 'accent-sky-600'
              }`}
              type="range"
              value={volume}
              min={MIN_VOL}
              max={MAX_VOL}
              step={0.01}
              onChange={(e) => setVolume(parseFloat(e.target.value))}
              title="Réglage du volume"
            />
          </div>
          {data.length != soundsTimestamps.length && (
            <div className="m-2 flex justify-center rounded-xl bg-red-500 bg-opacity-50 p-1">
              Il semblerait que des sons appartenant à cet intervalle n'aient
              pas été conservés 🗑️
            </div>
          )}
          <div className="flex flex-row justify-between">
            <span>
              {currentSoundKey && soundTexts[currentSoundKey]
                ? `"${soundTexts[currentSoundKey]}"`
                : ''}
            </span>
            <div className="flex flex-row gap-8">
              <TimeWindowDialog serial={serial} currentTime={currentTime} />
              <LabelsDialog soundKey={currentSoundKey} />
              <SequenceDialog serial={serial} currentTime={currentTime} />
              <EventDialog serial={serial} currentTime={currentTime} />
              {soundURLs && currentSoundKey && (
                <a href={soundURLs.current[currentSoundKey]}>
                  <Button title="Télécharger ce son">
                    <span role="img" aria-label="Télécharger">
                      📥
                    </span>
                  </Button>
                </a>
              )}
              <Button onClick={handlePlaybackRateClick}>{playbackRate}x</Button>
            </div>
          </div>

          <div className="flex flex-row items-center gap-5 p-2">
            <Button
              title={
                !isSimilaritySearchEnabled
                  ? 'La recherche est désactivée car le son est trop ancien'
                  : milvusLoading
                    ? 'Recherche en cours ...'
                    : 'Rechercher des sons similaires dans Milvus'
              }
              className="self-center px-4 py-2 align-middle text-base"
              disabled={!isSimilaritySearchEnabled || milvusLoading}
              onClick={async () => {
                if (!currentSoundKey) return
                searchSimilarParticles(currentSoundKey.replace('.ogg', ''))
              }}
            >
              Rechercher des sons similaires
            </Button>
            <Select
              className="inline-block self-center px-4 py-2 align-middle text-base"
              value={selectedMilvusMode}
              disabled={!isSimilaritySearchEnabled || milvusLoading}
              onChange={(event) =>
                setSelectedMilvusMode(event.target.value as MilvusMode)
              }
            >
              <option key={'all'} value={'all'}>
                {"Dans l'ensemble de la base de données."}
              </option>
              <option key={'serialOnly'} value={'serialOnly'}>
                {'Uniquement depuis cet ARI.'}
              </option>
              {bucketName === 'oso-sounds' && (
                <option key={'facilityOnly'} value={'facilityOnly'}>
                  {'Uniquement depuis cet établissement.'}
                </option>
              )}
              {bucketName === 'oso-resp-sounds' && (
                <option
                  key={'serialAndRecordsSessionOnly'}
                  value={'serialAndRecordsSessionOnly'}
                >
                  {'Uniquement depuis les mêmes ARI et records_session.'}
                </option>
              )}
            </Select>
            {milvusLoading && <Loader />}
            {!milvusLoading && similarParticles.length > 0 && (
              <>
                <div className="h-24 max-h-24 overflow-y-auto">
                  <ul>
                    {similarParticles.map((particle, index) => (
                      <li key={index} className="mb-1">
                        <a
                          href={`https://listen.oso-ai.com/${particle}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cursor-pointer underline"
                        >
                          <strong style={{ color: '#0369a1' }}>
                            {index + 1}:
                          </strong>{' '}
                          {particle}{' '}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <Button
                  title="Copier la liste des particules similaires dans le presse-papier"
                  className="inline-block self-center px-4 py-2 align-middle text-base"
                  disabled={!isSimilaritySearchEnabled}
                  onClick={copyAllParticlesToClipboard}
                >
                  <span role="img" aria-label="Copy">
                    {isListCopied ? 'Copié ! ✅' : 'Copier la liste 📋'}
                  </span>
                </Button>
              </>
            )}
          </div>

          <div className="flex flex-col gap-4 p-4">
            <div className="flex flex-row items-center gap-4">
              <Button
                title="Transcription et Traduction"
                className="px-6 py-2 text-base"
                onClick={handleSpeechProcessing}
                disabled={speechProcessingLoading}
              >
                {speechProcessingLoading
                  ? 'Traitement en cours...'
                  : 'Transcription et Traduction'}
              </Button>
              <DropdownCheckboxes
                options={LANGUAGE_OPTIONS.map((lang) => lang.name)}
                value={getLanguageNamesFromCodes(selectedLanguages)}
                onValidation={handleLanguageChange}
                resetValue={['Français']}
                disabled={speechProcessingLoading}
                maxSelections={4}
              >
                Sélectionner les langues (max 4)
              </DropdownCheckboxes>
              {speechProcessingLoading && <Loader />}
            </div>

            {!speechProcessingLoading && speechResult && (
              <div>
                {speechResult.originalText.trim() ? (
                  <>
                    <div>
                      <strong>Transcription :</strong>{' '}
                      {speechResult.originalText}
                    </div>
                    {speechResult.translatedText && (
                      <div>
                        <strong>Traduction :</strong>{' '}
                        {speechResult.translatedText}
                      </div>
                    )}
                    <div className="mt-2">
                      <Button
                        title="Copier dans le presse-papier"
                        className="px-4 py-2 text-base"
                        onClick={copyTranslationToClipboard}
                      >
                        <span role="img" aria-label="Copy">
                          {isListCopied ? 'Copié ! ✅' : 'Copier 📋'}
                        </span>
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="font-medium text-amber-600">
                    La detection de la parole a échoué.
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
